<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsCommentNum"
                >
                    <el-checkbox
                        v-model="queryFormModel.goodsComment"
                        true-label="1"
                        false-label="0"
                        label="处理中"/>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-upload
                        class="upload-demo dp-ib"
                        action="/spider/pupuGoods/uploadByZip"
                        :show-file-list="false"
                        accept=".zip"
                        :on-success="onImportSuccess"
                        :on-error="onImportError"
                        :on-progress="onImportProgress"
                    >
                        <el-button
                            type="success"
                            icon="el-icon-upload2"
                            size="small"
                        >
                            上传
                        </el-button>
                    </el-upload>
                </el-button-group>
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportExcel"
                    >
                        导出
                    </el-button>
                </el-button-group>
            </div>
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    prop="goodsName"
                    label="商品名称"
                    min-width="120"
                />
                <el-table-column
                    prop="goodsCategory"
                    label="商品类目"
                    min-width="80"
                />
                <el-table-column
                    prop="goodsCommentNum"
                    label="商品评论数"
                    min-width="50"
                />
                <el-table-column
                    label="商品图片"
                    min-width="70"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.goodsImageUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="goodsPrice"
                    label="商品价格"
                    min-width="50"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="120"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'PupuGoods',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                goodsName: '', // 商品名称
                goodsComment: '0',
            },
            flag: true,
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            loading: '',
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Spider.PupuGoods.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onImportProgress() {
            this.loading = this.$loading({
                lock: true,
                text: '上传中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
            });
        },

        onImportSuccess(response) {
            this.loading.close();
            if (response.success) {
                this.$alert(response.msg, '上传成功', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '确定',
                });
                this.getListData();
            } else {
                this.$alert(response.msg, '错误提示', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '确定',
                });
            }
        },
        onImportError(err) {
            this.loading.close();
            this.$alert(err.msg, '错误提示', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定',
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Spider.PupuGoods.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },

        // excel导出
        exportExcel() {
            this.$http.href('/spider/pupuGoods/exportPuPuGoods', {
                ...this.queryFormModel,
            });
        },
    },
};
</script>

<style lang="scss">
</style>
